<template>
  <div class="main-box" v-if="shopDetail" @click.stop="closemodel">
    <a-breadcrumb>
      <a-breadcrumb-item
        ><router-link to="/knowledgeMall/shopHome"
          >书课专区</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item v-if="$route.query.name"
        ><a @click="$router.go(-1)">{{
          $route.query.name
        }}</a></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">{{
        shopDetail.name
      }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="content">
      <div class="shopimg">
        <img class="img" :src="imgSrc" alt="" />
        <!-- 商品 -->
        <div
          class="imgList"
          v-if="
            $route.query.productId &&
            shopDetail.photoList &&
            shopDetail.photoList.length > 1
          "
        >
          <img
            :class="{ active: imgSrc == item.photo }"
            :src="item.photo"
            v-for="(item, index) in shopDetail.photoList"
            @click="imgSrc = item.photo"
            :key="index"
            alt=""
          />
        </div>
      </div>
      <div class="shopDetail">
        <p class="name" v-if="$route.query.productId">{{ shopDetail.name }}</p>
        <div
          class="shopMessage"
          :style="{ backgroundImage: 'url(' + imgSrc1 + ')' }"
        >
          <div class="price">
            <p class="title">价格：</p>
            <!-- 商品 -->
            <p
              class="new"
              :class="{ newFree: !shopDetail.couponPrice }"
              v-if="$route.query.productId && !!shopDetail.couponPrice"
            >
              ￥<span
                >{{ managePrice(shopDetail.couponPrice * 1)[0]
                }}<span class="new_point"
                  >.{{ managePrice(shopDetail.couponPrice * 1)[1] }}</span
                ></span
              >
            </p>
            <p
              class="new"
              :class="{ newFree: !shopDetail.couponPrice }"
              v-if="$route.query.productId && !shopDetail.couponPrice"
            >
              免费
            </p>
            <p class="old" v-if="$route.query.productId && shopDetail.original">
              ￥{{ (shopDetail.originalPrice * 1).toFixed(2) }}
            </p>
          </div>
          <div
            class="navs"
            v-if="couponList && couponList.length && shopDetail.couponPrice"
          >
            <span class="title">优惠券：</span>
            <span
              class="nav"
              v-for="(ite, ind) in couponList"
              :key="ind"
              v-show="ind < 4"
              >{{
                ite.type == 1
                  ? "满" + ite.fullPrice + "减" + ite.couponPrice
                  : ite.type == 3
                  ? "满" +
                    ite.discountPrice +
                    (ite.discountType == 1 ? "件" : "元") +
                    ite.discount +
                    "折"
                  : ""
              }}</span
            >
            <span
              class="button"
              @click.stop="couponFlag = !couponFlag"
              >领券<a-icon class="icon" type="down" v-if="!couponFlag" /><a-icon class="icon" v-else type="up" />
            </span>
            <div class="couponList" v-show="couponFlag">
              <div class="coupon" :class="{geted:ite.drawId}" v-for="(ite, ind) in couponList" :key="ind">
                <div class="couponLeft">
                  <!-- 满减券 -->
                  <p class="price" v-if="ite.type == 1">￥<span
                    :style="[
                      {
                        fontSize:(ite.couponPrice+'').length < 4?'30px':(ite.couponPrice+'').length == 4?'24px':'18px'
                      },
                      {
                        lineHeight:(ite.couponPrice+'').length <= 4?'45px':'37px'
                      }
                    ]
                    ">{{ite.couponPrice}}</span></p>
                  <!-- 折扣券 -->
                  <p class="price" v-else><span>{{ite.discount}}</span>折</p>
                  <p class="condition">{{ite.type == 1?('满'+ite.fullPrice):ite.type == 3?('满'+ite.discountPrice+(ite.discountType==1?'件':'元')):''}}可用</p>
                </div>
                <div class="couponRight">
                  <p class="couponName mallHidden">{{ite.name}}</p>
                  <p class="useTime">{{useTime(ite)}}</p>
                  <p class="scope mallHidden">【{{ite.receiveDesc}}】</p>
                </div>
                <div v-if="!ite.drawId" @click="receiveCoupon(ite)" class="couponBtn">领取</div>
                <div v-else class="couponStyle">已领取</div>
              </div>
            </div>
          </div>
        </div>
        <p
          v-if="$route.query.productId && shopDetail.slogan"
          class="introduce"
          v-html="shopDetail.slogan"
        ></p>
        <div class="introduce slogan" v-if="shopDetail.slogan">
          <div style="display: inline-block">
            <!-- <span class="span1"
              >Editor's Pick · <span class="white"></span
            ></span> -->
            <span class="backGround"
              >Editor's Pick <span class="white"></span
            ></span>
          </div>
          {{ font(shopDetail.abstracts) }}
        </div>
        <p v-if="$route.query.productId" class="menu">
          <span>发货地址：</span><span>{{ shopDetail.provice }}{{ shopDetail.city
          }}{{ shopDetail.area }}</span>
        </p>
        <p v-if="$route.query.productId" class="menu">
          <span>发货时间：</span><span class="detailMsg">{{ shopDetail.shipments }}</span>
        </p>
        <div class="menu">
          <span>配送至：</span>
          <div class="select-input">
            <a-input
              @click="onOpenAddress"
              v-model="addressText"
              :readOnly="true"
              placeholder="请选择地址"
            />
            <img
              class="icon"
              @click="onOpenAddress"
              :src="isOpenAddress ? downImg : openImg"
              alt=""
            />
            <div class="address-box" v-show="isOpenAddress">
              <!-- 自建地址 -->
              <div class="self-address" v-if="addressList.length">
                <a-select
                  :default-value="feightId"
                  style="width: 300px"
                  @change="handleChange"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="(item, index) in addressList"
                    :key="index"
                  >
                    {{ item.proviceName }}{{ item.cityName }}{{ item.areaName
                    }}{{ item.address }}
                  </a-select-option>
                </a-select>
                <img
                  class="icon"
                  @click="onOpenAddress"
                  :src="downImg"
                  alt=""
                />
              </div>
              <!-- 级联地址选择 -->
              <div class="address-inner">
                <div class="select">
                  <span
                    @click="getAddress(0)"
                    :class="{ spanHover: addressIndex == 0 }"
                    >{{ provinceName ? provinceName : "请选择" }}</span
                  >
                  <span
                    @click="getAddress(1)"
                    v-show="provinceName"
                    :class="{ spanHover: addressIndex == 1 }"
                    >{{ cityName ? cityName : "请选择" }}</span
                  >
                  <span
                    @click="getAddress(2)"
                    v-show="cityName && regionForm.area.length"
                    :class="{ spanHover: addressIndex == 2 }"
                    >{{ areaName ? areaName : "请选择" }}</span
                  >
                </div>
                <div class="region-list">
                  <div
                    class="item"
                    @click="onRegionItem(item)"
                    v-for="item in regionList"
                    :key="item.code"
                  >
                    <span
                      :class="{
                        hoverSpan:
                          item.name == provinceName ||
                          item.name == cityName ||
                          item.name == areaName,
                      }"
                      >{{ item.name }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p v-if="addressText">运费：{{ feightPriceText }}</p>
        </div>
        <div
          v-if="$route.query.productId"
          style="margin-top: 10px"
          class="menu"
        >
          <span>数量：</span>
          <div class="count">
            <a-icon class="icon" type="minus" @click="changeCount(-1)" />
            <a-input-number
              id="inputNumber"
              v-model="shopDetail.count"
              :min="1"
            />
            <a-icon class="icon" type="plus" @click="changeCount(1)" />
          </div>
        </div>
        <!-- <div
          class="buybutton"
          @click="
            (visible = true),
              (fontText = shopDetail.couponPrice ? '系统升级，请下载华夏云课堂app进行购买' : '系统升级，请下载华夏云课堂app进行学习')
          "
        >
          <a-icon type="shopping" />
          <p>立即购买</p>
        </div> -->
        <div class="buyBtns" v-if="!shopDetail.isGift">
          <div
            class="buybutton"
            @click="joinShopCar"
          >
            <a-icon type="shopping-cart" />
            <p>加入购物车</p>
          </div>
          <div
            class="buybutton"
            @click="buyNow"
          >
            <a-icon type="shopping" />
            <p>立即购买</p>
          </div>
        </div>
      </div>
    </div>
    <!-- tabs -->
    <div class="tabs-box">
      <!-- 切换 -->
      <div class="tabs">
        <a
          class="tabsdiv"
          v-if="
            $route.query.productId &&
            (shopDetail.type == 2 || shopDetail.type == 3)
          "
          href="javascript:;"
          @click="tabIndex = 1"
          :class="tabIndex == 1 ? 'a1' : ''"
        >
          <span>商品介绍</span>
        </a>
        <a
          class="tabsdiv"
          v-if="bookMessage()"
          href="javascript:;"
          @click="tabIndex = 2"
          :class="tabIndex == 2 ? 'a2' : ''"
        >
          <span>书籍信息</span>
        </a>
        <!-- <a
          v-if="shopDetail.couponPrice"
          class="tabsdiv"
          href="javascript:;"
          @click="tabIndex = 6"
          :class="tabIndex == 6 ? 'a2' : ''"
        >
          <span>评价</span>
        </a> -->
      </div>
      <!-- 视图 -->
      <div class="contenttab">
        <!-- 商品介绍 -->
        <div v-if="tabIndex == 1" v-html="shopDetail.pcDetail"></div>
        <!-- 书籍信息 -->
        <div v-else-if="tabIndex == 2">
          <!-- 书籍信息 -->
          <div class="catalogue relatedIntroduction" v-if="shopDetail.book">
            <div class="item" v-if="shopDetail.book.bookName">
              <div class="left">
                <span>书</span>
                <span>名：</span>
              </div>
              <div class="right">
                <div class="span">{{ shopDetail.book.bookName }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.isbnCode">
              <div class="left">ISBN：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.isbnCode }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.bookCname">
              <div class="left">
                <span>从</span>
                <span>书</span>
                <span>名：</span>
              </div>
              <div class="right">
                <div class="span">{{ shopDetail.book.bookCname }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.edition">
              <div class="left">
                <span>版</span>
                <span>次：</span>
              </div>
              <div class="right">
                <div class="span">{{ shopDetail.book.edition }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.press">
              <div class="left">
                <span>出</span>
                <span>版</span>
                <span>社：</span>
              </div>
              <div class="right">
                <div class="span">{{ shopDetail.book.press }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.author">
              <div class="left">
                <span>作</span>
                <span>者：</span>
              </div>
              <div class="right">
                <div class="span">{{ shopDetail.book.author }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.publicationTime">
              <div class="left">出版时间：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.publicationTime }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.authorRegion">
              <div class="left">作者地区：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.authorRegion }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.bookSize">
              <div class="left">开本/尺寸：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.bookSize }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.cipCategory">
              <div class="left">CIP分类：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.cipCategory }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.editor">
              <div class="left">编者：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.editor }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.keyword">
              <div class="left">关键字：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.keyword }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.numberPage">
              <div class="left">页数：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.numberPage }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.numberWord">
              <div class="left">字数：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.numberWord }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.packageCount">
              <div class="left">包册数：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.packageCount }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.price">
              <div class="left">定价：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.price }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.reader">
              <div class="left">读者对象：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.reader }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.recommend">
              <div class="left">编辑推荐：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.recommend }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.stamper">
              <div class="left">印张：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.stamper }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.suit !== null">
              <div class="left">是否套装：</div>
              <div class="right">
                <div class="span">
                  {{
                    shopDetail.book.suit === 0
                      ? "否"
                      : shopDetail.book.suit == 1
                      ? "是"
                      : ""
                  }}
                </div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.texture">
              <div class="left">内文用纸材质：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.texture }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.trademark">
              <div class="left">品牌：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.trademark }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.translator">
              <div class="left">译者：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.translator }}</div>
              </div>
            </div>
            <div class="item" v-if="shopDetail.book.wrap">
              <div class="left">包装：</div>
              <div class="right">
                <div class="span">{{ shopDetail.book.wrap }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 评价 -->
        <div v-else-if="tabIndex == 6">
          <CommentList :pruductId="this.$AES.decode_data(this.$route.query.productId)"/>
        </div>
      </div>
    </div>
    <!-- 商品推荐 -->
    <p class="moduletitle" v-if="$route.query.productId && goodShopList.length">
      <span></span>商品推荐
    </p>
    <div class="banner" v-if="$route.query.productId && goodShopList.length">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="(item, index) in goodShopList" :key="index">
          <div @click="routerPush(item)" class="industryitem">
            <div class="shopItem">
              <img class="img" :src="item.listPhoto" alt="" />
              <div class="shopintroduce">
                <p class="name onlyOneHidden">{{ item.name }}</p>
                <div class="introduce">
                  <span class="label" v-if="item.type == 1"
                    >{{ item.courseCategory }}
                    <span v-if="item.courseCategory && item.courseHour">|</span>
                    {{ item.courseHour }}</span
                  >
                  <span class="label" v-if="item.type == 4">{{
                    item.answerNumber
                  }}</span>
                  <span
                    class="navs"
                    v-for="(ite, ind) in item.couponList"
                    v-show="ind < 1 && item.couponPrice"
                    :key="ind"
                    >{{ ite }}</span
                  >
                </div>
                <div class="price">
                  <p class="new">
                    <span v-if="!item.couponPrice">免费</span>
                    <span v-else>
                      <span class="new_point">￥</span>{{
                      managePrice(item.couponPrice * 1)[0]
                    }}.<span class="new_point">{{managePrice(item.couponPrice * 1)[1]}}</span></span>
                  </p>
                  <p class="old" v-if="item.original">
                    ￥{{ (item.originalPrice * 1).toFixed(2) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <!-- 前进后退按钮 -->
        <div
          v-if="goodShopList.length > 4"
          class="swiper-button-prev swiper_btn swiper-button-white"
          slot="button-prev"
          @mouseover="changeMask(1)"
          @mouseout="changeMask(2)"
        >
          <div class="leftWrite"></div>
          <img
            v-show="!swiperLeft"
            src="@/assets/image/knowledgeMall/circularleft.png"
            alt=""
          />
          <img
            v-show="swiperLeft"
            src="@/assets/image/knowledgeMall/circularleft2.png"
            alt=""
          />
        </div>
        <div
          v-if="goodShopList.length > 4"
          class="swiper-button-next swiper_btn swiper-button-white"
          slot="button-next"
          @mouseover="changeMask(3)"
          @mouseout="changeMask(4)"
        >
          <div class="rightWrite"></div>
          <img
            v-show="!swiperRight"
            src="@/assets/image/knowledgeMall/circularright.png"
            alt=""
          />
          <img
            v-show="swiperRight"
            src="@/assets/image/knowledgeMall/circularright2.png"
            alt=""
          />
        </div>
      </swiper>
    </div>
    <Shopwarning :visible="visible" :fontText="fontText" />
    <Sidebar ref="sidebar"/>
  </div>
</template>

<script>
// import forTree from '@/components/forTree/index.vue'
import Sidebar from "@/components/sidebar.vue";
import CommentList from "@/components/commentList.vue";
export default {
  // 可用组件的哈希表
  components: { Sidebar, CommentList },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      couponFlag: false, // 优惠券弹框状态
      clientWidth: 0, // 视口宽度
      openImg: require("@/assets/image/selected/open.png"),
      downImg: require("@/assets/image/selected/down.png"),
      isOpenAddress: false,
      imgSrc1: require("@/assets/image/temporary/Group4627.png"),
      swiperLeft: false,
      swiperRight: false,
      feightPriceText: "",
      code: 0,
      regionForm: {
        province: [], // 省数据
        city: [], // 市数据
        area: [], // 区数据
      },
      provinceName: "", // 省名称
      cityName: "", // 市名称
      areaName: "", // 区名称
      regionList: [],
      feightId: "", // 运费模板id
      feightItem: {}, // 运费模板
      addressText: "", // 地址拼接
      addressIndex: 0, // 地址选择下标
      addressList: [], // 收货地址列表
      classList: [], // 商品-班期list
      visible: false,
      fontText: "", // 文本
      tabIndex: 1,
      formData: {},
      mallClass: false, // 更多期数状态
      imgSrc: require("@/assets/image/temporary/pic_nav.jpg"),
      tabMenu: ["全部", "213"],
      couponList: null, // 优惠券列表
      swiperOptions: {
        slidesPerView: 3.8,
        centeredSlides: false,
        centeredSlidesBounds: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          disabledClass: "my-button-disabled",
        },
        // Some Swiper option/callback...
      },
      // 包含课程列表
      courseList: [],
      // 图书教具列表
      productList: [],
      // 商品推荐列表
      goodShopList: [],
      // 套餐列表
      setmealList: [],
      shopDetail: null,
    };
  },
  // 事件处理器
  methods: {
    bookMessage() {
      let flag = false;
      if(this.shopDetail.book){
        let obj = JSON.parse(JSON.stringify(this.shopDetail.book))
        delete obj.id;
        delete obj.productId;
        Object.values(obj).map(item=>{
          if(!!item){
            flag = true;
          }
        })
      }
      return this.$route.query.productId && this.shopDetail.isBook && flag;
    },
    closemodel(){
      if(this.couponFlag) {
        this.couponFlag = false;
      }
    },
    useTime(ite) {
      if(ite.times == 2) {
        if(ite.startTime && ite.lapseTime) {
          return ite.startTime.split(' ')[0] + '-' + ite.lapseTime.split(' ')[0]
        }
      }else if(ite.times == 3) {
        if(ite.lapseTime) {
          return '至'+ ite.lapseTime.split(' ')[0]
        }
        if(ite.days) {
          return '领取后'+ite.days+'天内可用'
        }
      }else if(ite.times == 1) {
        return '不限'
      }
    },
    // 加入购物车
    joinShopCar(){
      // this.feightPriceText == "不配送"
      if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/loginIndex?type=2');
			}
      this.$ajax({
				url:'/hxclass-pc/pc-mall/shop/save',
				method:'post',
				params:{
					count:this.shopDetail.count, // 数量
					couponCode:'', // 优惠劵编码
					openTimeId:'', // 开班设置id
					price:this.shopDetail.couponPrice, // 价格  优惠价格-还没有计算优惠券
					productId:this.shopDetail.productId, // 商品id
					type:this.shopDetail.type, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
					userNo:this.$store.state.userInfo.userId, // 用户
				}
			}).then(res=>{
				if(res.code == 200 && res.success){
          this.$refs['sidebar'].getShopList()
					return this.$message.success('添加购物车成功');
				}else{
					this.$message.error(res.message)
				}
      })
      let record = [];
			if(this.productList && this.productList.length){
				this.productList.map(item=>{
					if(item.num>0){
						record.push({
							count:item.num, // 数量
							couponCode:'', // 优惠劵编码
							openTimeId:'', // 开班设置id
							price:item.couponPrice, // 价格  优惠价格-还没有计算优惠券
							productId:item.productId, // 商品id
							type:item.type, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
							userNo:this.$store.state.userInfo.userId, // 用户
						})
					}
				})
      }
    },
    // 立即购买
    buyNow(){
      if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/loginIndex?type=2');
			}
      if(this.feightPriceText == "不配送"){
				return this.$message.error('此商品不支持在当前地区销售');
			}
      let list = [];
			list.push({
				productId:this.shopDetail.productId, // 商品id
				count:this.shopDetail.count, // 数量")
				openTimeId:'', // 班级id
				type:this.shopDetail.type, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
				province:'', // 省
				city:'',// 市
				noGift:0, // 是否为赠品 0否1是t
				productList:[]
			})
      this.$store.commit('updateCouponList',null);
			this.$store.commit('updatelist',list);
			this.$store.commit('updateType',1);
			this.$store.commit('updateShortInvoiceId',null);
			this.$store.commit('updateInvoiceId',null);
			this.$store.commit('updateAddress',null);
			this.$router.push('/order/confirmOrder')
    },
    // 价格处理
    managePrice(con) {
      let txt = (con * 1).toFixed(2).split(".");
      return txt;
    },
    // 级联查询省市区
    getAddress(index) {
      this.addressIndex = index;
      this.regionList =
        index == 0
          ? this.regionForm.province
          : index == 1
          ? this.regionForm.city
          : this.regionForm.area;
    },
    // 点击 省/市
    onRegionItem(e) {
      // 点击的地区code
      this.code = e.code;
      if (this.addressIndex == 0) {
        // 存省名称
        this.addressIndex = 1;
        this.provinceName = e.name;
        this.getRegion();
        this.cityName = "";
        this.areaName = "";
        this.addressText = e.name;

        this.feightItem.provice = e.code;
      } else if (this.addressIndex == 1) {
        // 存市名称
        if (e.code != 120200) {
          // 天津市郊县无城市
          this.addressIndex = 2;
        }
        this.cityName = e.name;
        this.getRegion();
        this.areaName = "";
        this.addressText = this.provinceName + e.name;

        // 点击到市时即可计算运费
        this.feightItem.city = e.code;
        this.feightPrice();
      } else if (this.addressIndex == 2) {
        // 存区名称
        this.areaName = e.name;
        this.addressText = this.provinceName + this.cityName + e.name;
      }
    },
    // 查省市区
    getRegion() {
      this.$ajax({
        url: "/hxclass-pc/province-pc/pc/province/" + this.code,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (res.data.length) {
            if (this.addressIndex == 0) {
              // 存省
              this.regionForm.province = res.data;
            } else if (this.addressIndex == 1) {
              // 存市
              this.regionForm.city = res.data;
            } else if (this.addressIndex == 2) {
              // 存区
              this.regionForm.area = res.data;
            }
            this.regionList = res.data;
          } else {
            // 上层函数onRegionItem里已赋三级地区值，如果三级没城市的时候重置一下
            this.areaName = "";
            this.addressIndex = 1;
            this.regionForm.area = [];
          }
        }
      });
    },
    font(e) {
      return e.replace(/\<[^>]+>/g, "");
    },
    changeMask(e) {
      if (e == 1) {
        this.swiperLeft = true;
      } else if (e == 2) {
        this.swiperLeft = false;
      } else if (e == 3) {
        this.swiperRight = true;
      } else if (e == 4) {
        this.swiperRight = false;
      }
    },
    routerPush(e) {
      if (e.type == 1) {
        this.$router.push(
          "/knowledgeMall/courseDetail?productId=" +
            this.$AES.encode_data(e.productId + "") +
            "&code=" +
            e.code
        );
      } else if (e.type == 2 || e.type == 3) {
        this.$router.push(
          "/knowledgeMall/curriculumDetail?productId=" +
            this.$AES.encode_data(e.productId + "")
        );
        this.getShopDetail();
      } else if (e.type == 4) {
        this.$router.push(
          "/knowledgeMall/examDetail?productId=" +
            this.$AES.encode_data(e.productId + "")
        );
      }
    },
    handleChange(value) {
      // console.log(value);
      // 遍历寻找所选的地址
      this.addressList.forEach((e) => {
        if (e.id == value) {
          this.addressText = e.proviceName + e.cityName + e.areaName;
        }
      });
      let arr = this.addressList.filter((item) => {
        return item.id == value;
      });
      this.feightId = value;
      this.feightItem = arr[0];
      this.feightPrice();
    },
    // 改变商品数量
    changeCount(i) {
      if (i < 0) {
        if (this.shopDetail.count != 1) {
          this.$set(this.shopDetail, "count", this.shopDetail.count + i);
        }
      } else {
        this.$set(this.shopDetail, "count", this.shopDetail.count + i);
      }
      this.feightPrice();
    },
    // 获取商品详情
    getShopDetail() {
      this.$ajax({
        url: "/hxclass-pc/pc-mall/pc/mall/product-detail",
        method: "get",
        params: {
          productId: this.$AES.decode_data(this.$route.query.productId),
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (!res.data) {
            return this.$message.error("该商品已下架");
          }
          res.data.count = 1;
          if (this.$store.state.userInfo.isLogin) {
            this.getAddressList();
          }
          this.shopDetail = res.data;
          if (res.data.productList) {
            this.goodShopList = res.data.productList.filter((item) => {
              return item.nexus == 1;
            });
            if (res.data.type != 1) {
              this.productList = res.data.productList.filter((item) => {
                if (item.nexus == 1) {
                  return item.type == 2 || item.type == 3;
                }
              });
            }
          } else {
            this.goodShopList = [];
            this.productList = [];
          }
          if (res.data.photoList) {
            res.data.photoList = res.data.photoList.filter((item) => {
              return item.type == 2;
            });

            this.imgSrc = res.data.photoList[0].photo;
          }
          // 获取优惠券列表
          this.getCouponList(1);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    
    selectTime(){
      //时间戳
      // let time = +new Date();
      //时间戳
      let time = new Date().getTime();
      var date = new Date(time + 8*3600*1000); // 增加8小时
      return date.toJSON().substr(0, 19).replace('T', ' ');
    },

    // 领取优惠券
    receiveCoupon(e) {
      if(!this.$store.state.userInfo.userId){
        return this.$router.push('/login/loginIndex?type=2')
      }
      if(this.receiveCouponflag){
        return 
      }
      this.receiveCouponflag = true;
      this.$ajax({
        url:'/hxclass-pc/pc-mall/app/save-draw',
        method:'post',
        params:{
          couponId:e.couponId
        }
      }).then(res=>{
        this.receiveCouponflag = false;
        if(res.code == 200 && res.success){
          this.$message.success('领取成功');
          this.getCouponList();
        }else{
          this.$message.error(res.message);
        }
      })

      // 旧版
      // this.$ajax({
      //   url:'/hxclass-pc/pc-mall/shop/coupon-receive',
      //   method:'post',
      //   params:{
      //     couponId:e.couponId,
      //     receiver:this.$store.state.userInfo.userId,
      //     receiverTime:this.selectTime(),
      //     state:1
      //   }
      // }).then(res=>{
      //   this.receiveCouponflag = false;
      //   if(res.code == 200 && res.success){
      //     this.$message.success('领取成功');
      //     this.getCouponList();
      //   }else{
      //     this.$message.error(res.message);
      //   }
      // })
    },

    // 获取优惠券列表
    getCouponList(e) {
      this.$ajax({
        url: "/hxclass-pc/pc-mall/product/coupon",
        method: "get",
        params: {
          productId: this.$AES.decode_data(this.$route.query.productId + ""), //	购物车商品 1,2,3,4
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.couponList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
      
      // 旧版优惠券列表
      // this.$ajax({
      //   url: "/hxclass-pc/pc-mall/shop/coupon-list",
      //   method: "get",
      //   params: {
      //     productId: this.$AES.decode_data(this.$route.query.productId + ""), //	购物车商品 1,2,3,4
      //     type: e, // 1商品 2套餐
      //     productType: this.shopDetail.type,
      //   },
      // }).then((res) => {
      //   if (res.code == 200 && res.success) {
      //     this.couponList = res.data;
      //   } else {
      //     this.$message.error(res.message);
      //   }
      // });
    },
    onOpenAddress() {
      this.isOpenAddress = !this.isOpenAddress;
    },
    // 获取收货地址列表
    getAddressList() {
      this.$ajax({
        url: "/hxclass-pc/ship/address/list",
        method: "get",
        params: {
          pageNum: 1,
          pageSize: 10000,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (res.data.records.length) {
            this.addressList = res.data.records;
            let firstAddress = res.data.records[0];
            this.feightId = firstAddress.id;
            this.feightItem = firstAddress;
            this.addressText =
              firstAddress.proviceName +
              firstAddress.cityName +
              (firstAddress.areaName?firstAddress.areaName:'');
            this.feightPrice();
          }
        }
      });
    },
    // 计算运费
    feightPrice() {
      this.$ajax({
        url: "/hxclass-pc/pc-mall/pc/product-freight",
        method: "get",
        params: {
          city: this.feightItem.city, // 市编码
          count: this.shopDetail.count, // 数量
          freightId: this.shopDetail.freightId, // 运费模板id
          province: this.feightItem.provice, // 省编码
          weight: this.shopDetail.weight, // 重量
          total: Number(this.shopDetail.couponPrice) * this.shopDetail.count
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (res.data.code == 1) {
            this.feightPriceText = "包邮";
          } else if (res.data.code == 2) {
            this.feightPriceText = "不配送";
          } else if (res.data.code == 3) {
            this.feightPriceText = "￥" + res.data.price.toFixed(2);
          }
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.clientWidth = document.body.clientWidth;
    // 查询全部省
    this.getRegion();
    if (this.$route.query.productId) {
      this.getShopDetail();
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    clientWidth(newVal,oldVal){
      if(newVal<16000){
        this.$set(this.swiperOptions,'slidesPerView',3.7);
      }else{
        this.$set(this.swiperOptions,'slidesPerView',3.8);
      }
    }
  },
};
</script>

<style lang="less" scoped>
.relatedIntroduction {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 100px 0;
  .item {
    width: 430px;
    margin-right: 60px;
    display: flex;
    margin-bottom: 25px;
    .left {
      // width: 114px;
      text-align: right;
      font-size: 16px;
      font-family: PingFangMedium;
      color: #333333;
      line-height: 24px;
    }
    .right {
      flex: 1;
      font-size: 16px;
      font-family: PingFangMedium;
      color: #333333;
      line-height: 24px;
    }
  }
  @media screen and (max-width:16000px) {
    .item {
      width: 400px;
    }
  }
}
.slogan {
  padding-left: 16px;
  font-size: 16px;
  color: #666666;
  font-family: PingFangMedium;
  line-height: 24px;

  .span1 {
    padding: 0 0 0 3px;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    font-family: PingFangMedium;
    font-weight: 600;
    color: #ffffff;
    line-height: 21px;
    display: flex;
    align-items: center;
    background-color: rgba(255, 120, 0, 0.8);
    > span {
      font-size: 20px;
      line-height: 21px;
      align-items: center;
    }
  }
  .white {
    width: 0;
    height: 0;
    border-bottom: 21px solid white;
    border-left: 21px solid transparent;
  }
}
// 图书教具走马灯
.carouselList {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 108px;
  .item {
    width: 310px;
    height: 108px;
    background: #ffffff;
    border-radius: 5px;
    opacity: 1;
    border: 1px solid #ebebeb;
    margin-right: 10px;
    padding: 24px;
    display: flex;
    position: relative;
    .img {
      width: 60px;
      height: 60px;
    }
    .checked {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 4px;
      top: 50px;
      cursor: pointer;
    }
    .right {
      margin-left: 16px;
      flex: 1;
      .produceName {
        width: 182px;
        font-size: 14px;
        font-family: PingFangMedium;
        color: #333333;
        line-height: 21px;
      }
      .bottom {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .price {
          font-size: 14px;
          font-family: PingFangMedium;
          color: #EC6C01;
          line-height: 21px;
        }
        .count {
          width: 108px;
          height: 24px;
          display: flex;
          align-items: center;
          padding: 0 6px;
          border-radius: 2px;
          border: 1px solid #ebebeb;
          .icon {
            font-size: 14px;
            color: #666666;
          }
          ::v-deep.ant-input-number-handler-wrap {
            display: none;
          }
          ::v-deep.ant-input-number {
            height: 20px;
            width: 77px;
            border: 0;
          }
          ::v-deep.ant-input-number-input {
            height: 20px;
            text-align: center;
          }
          ::v-deep.ant-input-number:focus {
            box-shadow: none !important;
          }
        }
      }
    }
  }
  .item:last-child {
    margin: 0;
  }
}
.ant-carousel {
  width: 707px;
  height: 108px;
  .slick-slide {
    text-align: center;
    height: 160px;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
  }
  .custom-slick-arrow {
    width: 34px;
    height: 108px;
    font-size: 25px;
    color: #999999;
    background: #f5f5f5;
    border-radius: 5px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .custom-slick-arrow:before {
    display: none;
  }
  // .custom-slick-arrow:hover {
  //   opacity: 0.5;
  // }
  .slick-slide h3 {
    color: #fff;
  }
}
.setmealList {
  background-color: #ffffff;
  padding: 40px 0;
  .steps {
    display: block;
    margin: 10px auto;
    width: 820px;
    height: 10px;
  }
  .stepsfour {
    display: block;
    margin: 10px auto;
    width: 1025px;
    height: 10px;
  }
  .shopMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 25px;
      font-family: PingFangMedium;
      color: #333333;
      line-height: 25px;
      letter-spacing: 2px;
      margin-right: 25px;
    }
    .price {
      height: 58px;
      text-align: center;
      padding-top: 10px;
      .new {
        font-size: 18px;
        font-family: PingFangMedium;
        color: #EC6C01;
        line-height: 18px;
        span {
          font-size: 31px;
        }
      }
      .old {
        margin-top: 2px;
        font-size: 16px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #666666;
        line-height: 19px;
      }
    }
    .warning {
      font-size: 16px;
      font-family: PingFangMedium;
      color: #999999;
      line-height: 19px;
      margin-left: 29px;
    }
    .button {
      cursor: pointer;
      background: #15b7dd;
      box-shadow: 0px 5px 5px 1px rgba(206, 206, 206, 0.25);
      border-radius: 26px 26px 26px 26px;
      padding: 4px 36px;
      margin-left: 21px;
      font-size: 23px;
      font-family: PingFangMedium;
      color: #ffffff;
      line-height: 34px;
      .icon {
        color: #ffffff;
        font-size: 23px;
        margin-right: 6px;
      }
    }
  }
}
.setmealshopList {
  display: flex;
  justify-content: center;
  .industryitem {
    padding: 0 29px;
  }
}
.industryitem {
  cursor: pointer;
  position: relative;
  z-index: 3;
  .itemcontent {
    text-align: center;
    img {
      width: 257px;
      height: 224px;
      border-radius: 5px 5px 0px 0px;
    }
    .setmealcontent {
      width: 257px;
      height: 114px;
      margin: 0 auto;
      text-align: left;
      padding: 12px 10px 21px;
      transition: all 0.5s;
      margin-bottom: 10px;
      box-shadow: 0px 5px 5px 1px rgba(93, 131, 167, 0.1);
      .name {
        font-size: 18px;
        font-family: PingFangMedium;
        color: #000000;
        line-height: 28px;
      }
      .nav {
        display: flex;
        align-items: center;
        margin-top: 7px;
        .label {
          font-size: 14px;
          font-family: PingFangMedium;
          color: #1e97b4;
          line-height: 21px;
        }
        .oldPrice {
          font-size: 14px;
          font-family: PingFangMedium;
          text-decoration: line-through;
          color: #999999;
          line-height: 21px;
          margin-right: 9px;
        }
        .navs {
          font-size: 12px;
          font-family: PingFangMedium;
          color: #EC6C01;
          line-height: 18px;
          border-radius: 4px;
          border: 1px solid #EC6C01;
          padding: 2px 8px;
          font-weight: 400;
          margin-right: 5px;
        }
      }
      .newPrice {
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #EC6C01;
        line-height: 18px;
        margin-top: 11px;
        span {
          font-size: 18px;
        }
      }
    }
  }
  .warning {
    font-size: 16px;
    font-family: PingFangMedium;
    color: #999999;
    line-height: 19px;
  }
  .connector {
    position: absolute;
    right: -12.5px;
    top: 109px;
    .icon {
      width: 25px;
      height: 25px;
      font-size: 25px;
      color: #15b7dd;
    }
  }
}
.rightArrow {
  position: absolute;
  left: -12.5px;
  top: 109px;
  width: 25px;
  height: 25px;
}
.buyBox {
  text-align: center;
  width: 257px;
  padding-top: 74px;
  .title {
    font-size: 25px;
    font-family: PingFangMedium;
    color: #333333;
    line-height: 25px;
    letter-spacing: 2px;
    margin-bottom: 41px;
  }
  .price {
    height: 58px;
    text-align: center;
    .new {
      font-size: 18px;
      font-family: PingFangMedium;
      color: #EC6C01;
      line-height: 18px;
      span {
        font-size: 31px;
      }
    }
    .old {
      margin-top: 2px;
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #666666;
      line-height: 19px;
    }
  }
  .button {
    cursor: pointer;
    background: #15b7dd;
    box-shadow: 0px 5px 5px 1px rgba(206, 206, 206, 0.25);
    border-radius: 26px 26px 26px 26px;
    padding: 4px 36px;
    font-size: 23px;
    font-family: PingFangMedium;
    color: #ffffff;
    line-height: 34px;
    .icon {
      color: #ffffff;
      font-size: 23px;
      margin-right: 6px;
    }
  }
}
::v-deep.swiper-container {
  // margin: 0;
  width: 100%;
  padding: 0 50px;
}
::v-deep.swiper-button-next {
  position: absolute;
  right: 0px;
  top: 187px;
  width: 40px;
  height: 40px;
  background-image: url("~@/assets/image/knowledgeMall/circularright.png");
  background-repeat: no-repeat;
  background-size: 40px;
  .rightWrite {
    position: absolute;
    right: 0;
    top: -187px;
    z-index: 0;
    width: 60px;
    height: 500px;
    background: #fafafa;
  }
  img {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    // border-bottom-left-radius: 22px;
    // border-top-left-radius: 22px;
  }
}
.swiper-button-next:hover {
  background-image: url("~@/assets/image/knowledgeMall/circularright2.png");
}
::v-deep.swiper-button-next:after {
  opacity: 0;
}
::v-deep.swiper-button-prev:after {
  opacity: 0;
}
::v-deep.swiper-button-prev {
  position: absolute;
  left: 0;
  top: 187px;
  width: 40px;
  height: 40px;
  background-image: url("~@/assets/image/knowledgeMall/circularleft.png");
  background-repeat: no-repeat;
  background-size: 40px;
  .leftWrite {
    position: absolute;
    left: 0;
    top: -187px;
    z-index: 0;
    width: 60px;
    height: 1000px;
    background: #fafafa;
  }
  img {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    // border-bottom-left-radius: 22px;
    // border-top-left-radius: 22px;
  }
}
.swiper-button-prev:hover {
  background-image: url("~@/assets/image/knowledgeMall/circularleft2.png");
}
.industryitem {
  position: relative;
  .shopItem {
    // text-align: center;
    .name {
      width: 300px;
      margin: 0 auto;
      text-align: left;
    }
    .oldPrice {
      width: 300px;
      margin: 0 auto;
      text-align: left;
    }
  }
}
.main-box {
  padding-top: 80px;
  padding-bottom: 60px;
  .banner {
    background: #fff;
    border-radius: 4px;
    padding: 40px 24px;
    position: relative;
    // display: flex;
    .item {
      display: flex;
      .icon {
        margin: 77px 30px 0;
      }
    }
    .shopItem {
      .img {
        width: 300px;
        height: 260px;
        display: block;
        margin: 0 auto;
      }
      @media screen and (max-width:16000px) {
        .img {
          width: 264px;
          height: 229px;
        }
      }
      .shopintroduce {
        width: 300px;
        height: 133px;
        margin: 0 auto;
        border-radius: 0 0 5px 5px;
        box-sizing: border-box;
        border: 1px solid #15b7dd;
        border-top: 0px;
        padding: 12px 24px 20px;
        .name {
          width: 252px;
          font-size: 18px;
          font-family: PingFangMedium;
          color: #333333;
          line-height: 27px;
        }
        .introduce {
          display: flex;
          // justify-content: space-between;
          // align-items: center;
          margin-top: 7px;
          word-wrap: break-word;
          word-break: normal;
          line-height: 21px;
          span {
            display: inline-block;
          }
          .label {
            font-size: 14px;
            font-family: PingFangMedium;
            color: #1e97b4;
            line-height: 21px;
            white-space: nowrap;
            overflow: hidden;
            margin-right: 10px;
            text-overflow: ellipsis;
          }
          .navs {
            font-size: 12px;
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 18px;
            white-space: nowrap;
            border-radius: 4px;
            border: 1px solid #EC6C01;
            padding: 2px 8px;
            font-weight: 400;
          }
        }
        .price {
          display: flex;
          align-items: center;
          margin-top: 16px;
          .new {
            font-size: 14px;
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 21px;
            span {
              font-size: 20px;
            }
            .new_point {
              font-size: 16px;
            }
          }
          .old {
            font-size: 14px;
            font-family: PingFangMedium;
            font-weight: 400;
            text-decoration: line-through;
            color: #999999;
            line-height: 21px;
            margin-left: 9px;
          }
        }
      }
      @media screen and (max-width:16000px) {
        .shopintroduce {
          width: 264px;
          .name {
            width: 214px;
          }
        }
      }
    }
  }
  .moduletitle {
    padding: 10px 40px;
    margin-top: 40px;
    font-size: 24px;
    font-family: PingFangMedium;
    font-weight: bold;
    color: #15b7dd;
    line-height: 28px;
    display: flex;
    align-items: center;
    background: #f8f8f8;
    span {
      background-color: #15b7dd;
      height: 19px;
      width: 4px;
      border-radius: 7px;
      margin-right: 12px;
    }
  }
  .content {
    margin-top: 24px;
    padding: 40px;
    border-radius: 5px;
    background: #ffffff;
    display: flex;
    .shopimg {
      .img {
        width: 496px;
        height: 496px;
        border-radius: 5px;
      }
      @media screen and (max-width:16000px) {
        .img {
          width: 426px;
          height: 426px;
        }
      }
      .imgList {
        width: 426px;
        overflow: auto;
        display: flex;
        margin-top: 12px;
        padding-bottom: 3px;
        .active {
          border: 1px solid #15b7dd;
        }
        img {
          width: 60px;
          height: 60px;
          margin-right: 16px;
          border-radius: 2px;
          box-sizing: border-box;
          cursor: pointer;
        }
        @media screen and (max-width:16000px) {
          img {
            width: 52px;
            height: 52px;
          }
        }
        img:last-child {
          margin: 0;
        }
      }
    }
    .shopDetail {
      margin-left: 30px;
      flex: 1;
      .name {
        font-size: 20px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #000000;
        line-height: 30px;
        span {
          display: inline-block;
          width: 44px;
          height: 23px;
          text-align: center;
          background: rgba(21, 183, 221, 0.12);
          border-radius: 4px 4px 4px 4px;
          margin-right: 8px;
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: bold;
          color: #15b7dd;
          line-height: 23px;
        }
      }
      .describe {
        margin-top: 16px;
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #1e97b4;
        line-height: 21px;
      }
      .introduce {
        padding: 24px 16px;
        font-size: 18px;
        color: #666666;
        font-family: PingFangMedium;
        line-height: 24px;
        .backGround{
          padding-right: 24px;
          background-image: url(~@/assets/image/tag.png);
          background-size: 100% 100%;
          white-space: nowrap;
          height: 24px;
          padding: 0 0 0 3px;
          text-align: center;
          display: inline-block;
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: 600;
          color: #ffffff;
          line-height: 24px;
          background-color: rgba(255, 120, 0, 0.8);
        }
      }
      .menu {
        padding: 24px 16px;
        height: 24px;
        font-size: 14px;
        font-family: PingFangMedium;
        color: #333333;
        line-height: 24px;
        display: flex;
        // align-items: center;
        p {
          margin-left: 30px;
        }
        span {
          width: 78px;
          font-size: 14px;
          font-family: PingFangMedium;
          color: #666666;
          line-height: 21px;
          white-space: nowrap;
        }
        .detailMsg{
          flex: 1;
          white-space: pre-wrap;
          margin-bottom: 10px;
        }
        .select-input {
          width: 300px;
          position: relative;
          /deep/ .ant-input {
            height: 41px;
            cursor: pointer;
          }
          .icon {
            width: 20px;
            position: absolute;
            right: 5px;
            top: 10px;
            cursor: pointer;
          }
          .address-box {
            position: absolute;
            z-index: 999;
            padding: 16px;
            top: 40px;
            left: 0;
            width: 460px;
            background: #fff;
            border: 1px solid #3dd1eb;
            .self-address {
              display: flex;
              justify-content: space-between;
            }
            .address-inner {
              margin-top: 10px;
              .select {
                display: flex;
                border-bottom: 1px solid #15b7dd;
                > span {
                  display: inline-block;
                  position: relative;
                  top: 1px;
                  color: #666666;
                  margin-right: 10px;
                  width: auto;
                  max-width: calc(100% / 3);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-weight: 400;
                  font-size: 14px;
                  padding: 0 7px;
                  border: 1px solid #ebebeb;
                  border-bottom: 1px solid #15b7dd;
                  cursor: pointer;
                }
                .spanHover {
                  color: #333333;
                  border: 1px solid #15b7dd;
                  border-bottom: 1px solid #ffffff;
                }
              }
              .region-list {
                margin-top: 8px;
                display: flex;
                flex-wrap: wrap;
                .item {
                  width: calc(100% / 3);
                  > span {
                    display: inline-block;
                    width: auto;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: 24px;
                    padding: 2px 4px;
                    color: #333333;
                    cursor: pointer;
                  }
                  .hoverSpan {
                    color: #ffffff;
                    background: #15b7dd;
                    border-radius: 4px;
                  }
                }
              }
            }
          }
        }
        .count {
          width: 108px;
          height: 41px;
          display: flex;
          align-items: center;
          padding: 0 6px;
          border-radius: 2px;
          border: 1px solid #ebebeb;
          .icon {
            font-size: 14px;
            color: #666666;
          }
          ::v-deep.ant-input-number-handler-wrap {
            display: none;
          }
          ::v-deep.ant-input-number {
            height: 20px;
            width: 77px;
            border: 0;
          }
          ::v-deep.ant-input-number-input {
            height: 20px;
            text-align: center;
          }
          ::v-deep.ant-input-number:focus {
            box-shadow: none !important;
          }
        }
      }
      .classDate {
        margin-left: 16px;
        display: flex;
        .title {
          width: 71px;
          font-size: 14px;
          font-family: PingFangMedium;
          color: #666666;
          line-height: 21px;
        }
        .courseList {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          .courseItem {
            position: relative;
            padding: 18px;
            box-shadow: 0px 4px 10px 1px rgba(93, 131, 167, 0.15);
            display: flex;
            width: 324px;
            margin-left: 16px;
            margin-bottom: 16px;
            img {
              width: 60px;
              height: 60px;
            }
            .courseDateList {
              width: 624px;
              position: absolute;
              top: 90px;
              left: 0;
              background-color: #ffffff;
              z-index: 5;
              box-shadow: 0px 4px 10px rgba(93, 131, 167, 0.15);
              padding: 26px 16px 20px;
              display: flex;
              flex-wrap: wrap;
              .courseDateItem:nth-child(3n + 2) {
                margin: 0;
              }
              .courseDateItem {
                width: 186px;
                padding: 5px 17px;
                font-size: 14px;
                font-family: PingFangMedium;
                font-weight: 400;
                color: #333333;
                line-height: 28px;
                border-radius: 4px;
                margin-right: 17px;
                border: 1px solid #ebebeb;
                .top {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .name {
                    width: 85px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 28px;
                  }
                  .num {
                    span {
                      color: #15b7dd;
                    }
                  }
                }
              }
              .active {
                color: #ffffff;
                background: #15b7dd;
                .top {
                  .name {
                    color: #ffffff;
                  }
                  .num {
                    span {
                      color: #ffffff;
                    }
                  }
                }
              }
            }
            .courseMessage {
              padding-left: 16px;
              .name {
                width: 208px;
                font-size: 14px;
                font-family: PingFangMedium;
                font-weight: 400;
                color: #333333;
                line-height: 21px;
              }
              .findClassDate {
                cursor: pointer;
                float: right;
                margin-top: 21px;
                text-align: right;
                font-size: 12px;
                font-family: PingFangMedium;
                font-weight: 400;
                color: #15b7dd;
                line-height: 18px;
              }
            }
          }
        }
        .classList {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          .classItem {
            width: 225px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #ebebeb;
            margin-right: 16px;
            margin-bottom: 16px;
            padding: 5px 0;
            text-align: center;
            .classname {
              font-size: 14px;
              font-family: PingFangMedium;
              color: #333333;
              line-height: 28px;
            }
            .time {
              font-size: 14px;
              font-family: PingFangMedium;
              color: #333333;
              line-height: 28px;
            }
          }
          .classItem:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
      .buybutton {
        width: 142px;
        height: 46px;
        background: #15b7dd;
        border-radius: 23px;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        margin-left: 16px;
        &:hover::before {
          left: 100%;
        }
        p {
          margin-left: 5px;
        }
      }
      .buybutton::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: -100%;
        background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, .5), transparent);
        transition: all .5s;
      }
      .showAll {
        margin-bottom: 24px;
        margin-left: 71px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangMedium;
        color: #999999;
        line-height: 21px;
        span {
          cursor: pointer;
        }
        .icon {
          margin-left: 4px;
        }
      }
      .shopMessage {
        margin-top: 20px;
        padding: 30px 16px 24px 16px;
        background: rgba(21, 183, 221, 0.05);
        position: relative;
        // height: 142px;
        // overflow: hidden;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        .studyNum {
          position: absolute;
          right: 27px;
          top: 30px;
          .num {
            font-size: 16px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #15b7dd;
            line-height: 24px;
            text-align: right;
          }
          .font {
            font-size: 14px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #666666;
            line-height: 21px;
          }
        }
        .title {
          width: 72px;
          font-size: 14px;
          font-family: PingFangMedium;
          color: #666666;
          line-height: 21px;
        }
        .price {
          display: flex;
          align-items: center;
          .new {
            font-size: 16px;
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 21px;
            margin-top: -5px;
            span {
              font-size: 28px;
            }
            .new_point {
              font-size: 16px;
            }
          }
          .newFree {
            font-size: 24px;
            margin-top: -9px;
          }
          .old {
            font-size: 16px;
            font-family: PingFangMedium;
            font-weight: 400;
            text-decoration: line-through;
            color: #999999;
            line-height: 21px;
            margin-left: 20px;
          }
        }
        .navs {
          display: flex;
          // justify-content: space-between;
          // align-items: center;
          margin-top: 26px;
          word-wrap: break-word;
          word-break: normal;
          line-height: 21px;
          position: relative;
          .couponList{
            position: absolute;
            z-index: 2;
            top: 26px;
            left: 90px;
            background: #FFFFFF;
            border-radius: 5px;
            box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.1600);
            padding: 30px 20px 16px;
            .coupon{
              width: 322px;
              margin-bottom: 16px;
              padding-right: 15px;
              height: 80px;
              display: flex;
              align-items: center;
              background-image: url(~@/assets/image/knowledgeMall/coupon_1.png);
              background-size: 100% 100%;
              .couponLeft{
                padding-right: 20px;
                width: 93px;
                text-align: center;
                .price{
                  display: flex;
                  justify-content: center;
                  align-items: flex-end;
                  font-size: 16px;
                  font-family: PingFangRegular;
                  font-weight: 300;
                  color: #3A3330;
                  line-height: 37px;
                  span{
                    font-size: 30px;
                    line-height: 45px;
                    font-family: PingFangMedium;
                  }
                }
                .condition{
                  margin-top: -5px;
                  width: 80px;
                  font-size: 10px;
                  font-family: PingFangRegular;
                  font-weight: 400;
                  color: #3A3330;
                  line-height: 15px;
                  transform: scale(0.9);
                }
              }
              .couponRight{
                flex: 1;
                .couponName{
                  margin-left: -10px;
                  font-size: 14px;
                  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                  font-weight: 500;
                  color: #333333;
                  line-height: 14px;
                }
                .useTime{
                  margin-left: -10px;
                  margin-top: 7px;
                  font-size: 12px;
                  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                  font-weight: 400;
                  color: #999999;
                  line-height: 8px;
                  transform: scale(0.9);
                }
                .scope{
                  margin-top: 8px;
                  font-size: 10px;
                  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                  font-weight: 400;
                  color: #842C17;
                  line-height: 10px;
                }
              }
              .couponBtn{
                cursor: pointer;
                background: linear-gradient(134deg, #FFE4C2 0%, #FFD39F 100%);
              }
              .couponStyle{
                background: linear-gradient(134deg, #FFE4C2 0%, #FFD39F 100%);
              }
              .couponBtn,
              .couponStyle{
                text-align: center;
                width: 60px;
                height: 25px;
                border-radius: 2px;
                font-size: 14px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #333333;
                line-height: 25px;
              }
            }
            .coupon:last-child{
              margin-bottom: 0;
            }
            .geted{
              opacity: 0.5;
              // background-image: url(~@/assets/image/knowledgeMall/coupon_2.png);
            }
          }
          span {
            display: inline-block;
          }
          .nav {
            font-size: 12px;
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 18px;
            border-radius: 4px;
            border: 1px solid #EC6C01;
            padding: 2px 8px;
            font-weight: 400;
            margin-right: 8px;
          }
          .button {
            margin-left: 20px;
            font-size: 14px;
            font-family: PingFangMedium;
            color: #333333;
            line-height: 21px;
            padding-left: 20px;
            border-left: 1px dashed #999999;
            cursor: pointer;
          }
        }
        .giftList {
          display: flex;
          align-items: top;
          margin-top: 24px;
          .giftItem {
            display: flex;

            img {
              width: 40px;
              height: 40px;
            }
            .message {
              width: 68px;
              margin: 0 16px 0 8px;
              .giftName {
                font-size: 14px;
                font-family: PingFangMedium;
                color: #333333;
                line-height: 21px;
              }
              .count {
                margin-top: 5px;
                font-size: 12px;
                font-family: PingFangMedium;
                color: #333333;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
  }
}
.tabs-box {
  margin-top: 46px;
  background-color: #fff;
  padding: 75px 40px 40px;
  margin-bottom: 50px;
  position: relative;
  .tabs {
    height: 56px;
    background-color: #f8f8f8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 0 20px;

    .tabsdiv {
      // margin-right: 40px;
      // padding: 10px 20px;
      width: 182px;
      height: 64px;
      font-size: 20px;
      font-family: PingFangMedium;
      position: relative;
      color: #666;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .a1 {
      background-image: url("../../assets/image/curriculum/20220607-134028.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      // transition: all 0.3s;
      // font-size: 24px;
      color: @theme;
    }
    .a2 {
      background-image: url("../../assets/image/curriculum/20220506-144134.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      // transition: all 0.3s;
      // font-size: 24px;
      color: @theme;
    }
  }
}

.contenttab {
  overflow: hidden;
  /deep/img {
    max-width: 100%;
  }
}

/* 整个滚动条 */
::-webkit-scrollbar {
    /* 对应纵向滚动条的宽度 */
    width: 10px;
    /* 对应横向滚动条的宽度 */
    height: 5px;
}

/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
    background-color: @theme;
    border-radius: 32px;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
    background-color: #eee1e1;
    border-radius: 32px;
}
</style>
